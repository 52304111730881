import axios from 'axios'
import {
  getCodigoRetencion,
  getByIsActivoCodigoRetencion,
  postCodigoRetencion,
  putCodigoRetencion,
} from '@/utils/paths'

const pathGetCodigoRetencion = getCodigoRetencion
const pathGetByIsActivoCodigoRetencion = getByIsActivoCodigoRetencion
const pathPostCodigoRetencion = postCodigoRetencion
const pathPutCodigoRetencion = putCodigoRetencion

export function codigoRetencionResource() {
  function findAllCodigoRetencion() {
    return new Promise((resolve, reject) => {
      axios
        .get(pathGetCodigoRetencion)
        .then(results => {
          resolve(results.data)
        })
        .catch(e => {
          reject(e.response)
        })
    })
  }
  function findAllByIsActivoCodigoRetencion() {
    return new Promise((resolve, reject) => {
      axios
        .get(pathGetByIsActivoCodigoRetencion)
        .then(results => {
          resolve(results.data)
        })
        .catch(e => {
          reject(e.response)
        })
    })
  }
  function saveCodigoRetencion(CodigoRetencionDto) {
    return new Promise((resolve, reject) => {
      axios
        .post(pathPostCodigoRetencion, CodigoRetencionDto)
        .then(results => {
          resolve(results.data)
        })
        .catch(e => {
          reject(e.response)
        })
    })
  }
  function updateCodigoRetencion(id, CodigoRetencionDto) {
    return new Promise((resolve, reject) => {
      axios
        .put(pathPutCodigoRetencion + id, CodigoRetencionDto)
        .then(results => {
          resolve(results.data)
        })
        .catch(e => {
          reject(e.response)
        })
    })
  }
  return {
    findAllCodigoRetencion,
    findAllByIsActivoCodigoRetencion,
    saveCodigoRetencion,
    updateCodigoRetencion,
  }
}
