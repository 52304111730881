<template>
  <div>
    <b-card>
      <h4>{{ titulo }}</h4>
      <cuentas-bancarias
        v-if="editando"
        :item="selectedItem"
        @cancelar="cancelar"
        @guardar="guardar"
      />
      <div v-if="!editando">
        <b-row>
          <!-- -<b-col md="6">
            <b-form @submit.prevent="onSubmit">

              <b-input-group>
                <b-input-group-append>
                  <b-button
                    v-if="hasContent"
                    id="btnClearSearchUser"
                    v-b-tooltip.hover="'Limpiar filtro'"
                    class="closeStyle"
                    size="sm"
                    @click="() => clearSearch()"
                  >
                    <font-awesome-icon icon="fa-solid fa-eraser" />
                  </b-button>
                </b-input-group-append>
                <b-form-input
                  id="txtFiltro"
                  v-model="nroCuenta"
                  :disabled="isBussy"
                  autocomplete="off"
                  placeholder=" Búsqueda por Número de cuenta"
                />
                <b-input-group-prepend>
                  <b-button
                    id="btnSearchCuentaBancaria"
                    v-b-tooltip.hover
                    title="Buscar cuenta bancaria"
                    size="sm"
                    variant="primary"
                    :disabled="isBussy"
                    @click="filterByNumeroCuenta()"
                  >
                    <feather-icon icon="SearchIcon" />
                  </b-button>
                </b-input-group-prepend>

              </b-input-group>
            </b-form>
          </b-col> -->
          <b-col
            md="12"
            class="text-right"
          >
            <b-button
              v-if="permisosIns.includes(nameModulo)"
              id="btnAddCuentaBancaria"
              v-b-tooltip.hover
              squared
              style="float: right;"
              size="sm"
              title="Nueva cuenta bancaria"
              variant="success"
              @click="() => addItem()"
            >
              <feather-icon icon="PlusIcon" />
            </b-button>
          </b-col>
        </b-row>
        <b-table
          id="tblEmpCate"
          :busy="isBussy"
          :items="options"
          :fields="fieldsOptions"
          outlined
          show-empty
          small
          hover
          sticky-header="70vh"
          :no-border-collapse="true"
          empty-text="No se encontraron resultados para esta búsqueda"
          @row-dblclicked="editarItem"
        >
          <template v-slot:table-busy>
            <div class="text-center">
              <b-spinner />
            </div>
          </template>
          <template #cell(habilitado)="row">
            <small>
              {{ row.item.habilitado === true ? 'Activo' : 'Inactivo' }}
            </small>

          </template>
          <template #cell(actions)="row">
            <div
              class="btn-group"
              role="group"
              aria-label="Basic example"
            >
              <b-button
                v-if="permisosUpd.includes(nameModulo)"
                v-b-tooltip.hover.righttop="'Editar item'"
                size="sm"
                @click="editarItem(row.item, row.index, $event.target)"
              >
                <font-awesome-icon icon="fa-regular fa-pen-to-square" />
              </b-button>
            </div>
          </template>
        </b-table>
      </div>

    </b-card>
  </div>
</template>
<script>
import { mapGetters } from 'vuex'
import { cuentaBancariaResource } from '@/services/cuentaBancariaResource'
import CuentasBancarias from '@/components/Ambs/CuentasBancarias/CuentasBancarias.vue'

export default {
  components: {
    CuentasBancarias,
  },
  data() {
    return {
      editando: false,
      nroCuenta: null,
      options: null,
      nameModulo: 'ABM_PARAMETROS_DDJJ',
      fieldsOptions: [
        {
          key: 'entidadBancaria.entidadBancaria', label: 'Banco',
        },
        {
          key: 'dominio.nombre', label: 'Tipo cuenta',
        },
        {
          key: 'numeroCuenta', label: 'Número cuenta',
        },
        {
          key: 'numeroCbu', label: 'Número CBU',
        },
        {
          key: 'habilitado', label: 'Estado',
        },
        { key: 'actions', label: 'Acciones', thStyle: { width: '10%' } },
      ],
      isBussy: false,
    }
  },
  computed: {
    titulo() {
      let titulo = 'Gestión cuentas bancarias'
      if (this.editando) {
        if (this.selectedItem !== null) {
          titulo = 'Edición cuenta bancaria'
        } else {
          titulo = 'Nueva cuenta bancaria'
        }
      }
      return titulo
    },
    hasContent() {
      return (this.nroCuenta != null)
    },
    ...mapGetters(
      {
        permisosVer: 'getPermisosVer',
        permisosDel: 'getPermisosDel',
        permisosIns: 'getPermisosIns',
        permisosUpd: 'getPermisosUpd',
      },
    ),
  },
  created() {
    this.getLista()
  },
  methods: {
    addItem() {
      this.selectedItem = null
      this.editando = true
    },
    editarItem(item) {
      this.selectedItem = item
      this.editando = true
    },
    getLista() {
      this.isBussy = true
      cuentaBancariaResource().findAllCuentasBancarias().then(result => {
        this.options = result
      }).catch(error => {
        console.error('error:', error)
      })
        .finally(() => {
          this.isBussy = false
        })
    },
    cancelar() {
      this.editando = false
      this.getLista()
    },
    guardar() {
      this.getLista()
      this.editando = false
    },
    filterByNumeroCuenta() {
      this.isBussy = true
      cuentaBancariaResource().findByNumeroCuenta(this.nroCuenta).then(result => {
        this.options = result
      }).catch(error => {
        console.error('error:', error)
      })
        .finally(() => {
          this.isBussy = false
        })
    },
    clearSearch() {
      this.nroCuenta = ''
      this.getLista()
    },
  },
}
</script>
