<template>
  <div>
    <validation-observer ref="abmCodigoRetencionForm">
      <b-form>
        <b-overlay
          :show="isWorking"
          :rounded="overlayConfig.rounded"
          :variant="overlayConfig.variant"
          :opacity="overlayConfig.opacity"
          :blur="overlayConfig.blur"
        >
          <template #overlay>
            <div class="mt-2">
              <div style="text-align: center">
                <span class="spinner-border " />
              </div>
              <div style="text-align: center">
                <small>{{ overlayConfig.message }}</small>
              </div>
            </div>
          </template>
          <b-card>
            <b-row>
              <b-col md="6">
                <b-form-group
                  id="frmNombreCodigoRetencion"
                  label="Nombre"
                >
                  <validation-provider
                    name="nombreCodigoRetencion"
                    rules="required|max:255"
                  >
                    <div slot-scope="{ errors, failedRules }">
                      <b-form-input
                        id="txtNombreCodigoRetencion"
                        v-model="itemForm.nombre"
                        :state="errors.length > 0 ? false : null"
                        autocomplete="off"
                      />
                      <small
                        v-if="failedRules.required"
                        class="text-danger"
                      >Nombre obligatorio.</small>
                      <small
                        v-if="failedRules.max"
                        class="text-danger"
                      >Máximo 255 caracteres.</small>
                    </div>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col md="6">
                <b-form-group
                  id="frmNombreCustomCodigoRetencion"
                  label="Nombre Corto"
                >
                  <validation-provider
                    name="nombreCustomCodigoRetencion"
                    rules="required|max:30"
                  >
                    <div slot-scope="{ errors, failedRules }">
                      <b-form-input
                        id="txtNombreCustomCodigoRetencion"
                        v-model="itemForm.nombreCorto"
                        :state="errors.length > 0 ? false : null"
                        autocomplete="off"
                      />
                      <small
                        v-if="failedRules.required"
                        class="text-danger"
                      >Nombre corto obligatorio.</small>
                      <small
                        v-if="failedRules.max"
                        class="text-danger"
                      >Máximo 30 caracteres.</small>
                    </div>
                  </validation-provider>
                </b-form-group>
              </b-col>
            </b-row>
            <b-row>
              <b-col md="6">
                <b-form-group
                  id="frmDescripcionCodigoRetencion"
                  label="Descripción"
                >
                  <validation-provider
                    name="descripcionCodigoRetencion"
                    rules="max:255"
                  >
                    <div slot-scope="{ errors, failedRules }">
                      <b-form-input
                        id="txtDescripcionCodigoRetencion"
                        v-model="itemForm.descripcion"
                        :state="errors.length > 0 ? false : null"
                        autocomplete="off"
                      />
                      <small
                        v-if="failedRules.max"
                        class="text-danger"
                      >Máximo 255 caracteres.</small>
                    </div>
                  </validation-provider></b-form-group>
              </b-col>
              <b-col md="6">
                <b-form-group
                  id="frmCuentaBancaria"
                  label="Cuenta bancaria"
                >
                  <validation-provider
                    name="CuentaBancaria"
                    rules="required"
                  >
                    <div slot-scope="{ errors, failedRules }">

                      <small v-if="isBusyBuscandoCuentaBancaria">
                        <span
                          v-if="isBusyBuscandoCuentaBancaria"
                          class="spinner-border spinner-border-sm"
                          aria-hidden="true"
                        /> Buscando cuentas bancarias
                      </small>
                      <b-form-select
                        v-if="!isBusyBuscandoCuentaBancaria"
                        id="selectCuentaBancaria"
                        v-model="itemForm.cuentaBancaria.id"
                        v-b-tooltip.hover="'Seleccione la Cuenta bancaria'"
                        :state="errors.length > 0 ? false : null"
                        :aria-busy="isBusyBuscandoCuentaBancaria ? 'true' : 'false'"
                        :disabled="isBusyBuscandoCuentaBancaria"
                        rounded
                      >
                        <template #first>
                          <b-form-select-option
                            v-if="!isBusyBuscandoCuentaBancaria"
                            :value="null"
                            disabled
                          >
                            Seleccione
                          </b-form-select-option>
                        </template>
                        <b-form-select-option
                          v-for="option in optionsCuentaBancaria"
                          :key="option.id"
                          :value="option.id"
                        >
                          {{ concatenatedText(option) }}
                        </b-form-select-option>
                      </b-form-select>
                      <small
                        v-if="failedRules.required && !isBusyBuscandoCuentaBancaria"
                        class="text-danger"
                      >
                        Cuenta bancaria obligatoria.
                      </small>
                    </div>
                  </validation-provider>
                </b-form-group>
              </b-col>
            </b-row>
            <b-row>
              <b-col md="2">
                <b-form-group
                  id="frmChecknCuentaBancaria"
                  label="Estado"
                >
                  <b-form-checkbox
                    v-model="itemForm.habilitado"
                    v-b-tooltip.hover="'Activar / Desactivar'"
                    class="custom-control-success"
                    name="check-button"
                    switch
                  >
                    <small class="switch-icon-left">
                      <feather-icon icon="CheckIcon" />
                    </small>
                  </b-form-checkbox>
                </b-form-group>
              </b-col>
            </b-row>
          </b-card>
        </b-overlay>
        <hr>
        <b-row>
          <div>
            <b-col md="12">
              <b-button
                id="btnGCancelarCodigoRetencion"
                v-b-tooltip.hover
                title="Volver"
                variant="light"
                squared
                :disabled="isWorking"
                @click="() => cancelar()"
              >
                <feather-icon icon="ArrowLeftIcon" />
              </b-button>
            </b-col>
          </div>
          <div class="align-self-end ml-auto">
            <b-col md="12">
              <b-button
                v-if="permisosIns.includes(nameModulo)"
                id="btnGuardarCodigoRetencion"
                v-b-tooltip.hover
                variant="primary"
                squared
                title="Guardar"
                :disabled="isWorking"
                type="submit"
                @click.prevent="validationItemsForm"
              >
                <font-awesome-icon
                  v-if="!isWorking"
                  icon="fa-solid fa-floppy-disk"
                />
                <span
                  v-if="isWorking"
                  class="spinner-border spinner-border-sm"
                  aria-hidden="true"
                />
              </b-button>
            </b-col>
          </div>
        </b-row>
      </b-form>
    </validation-observer>
  </div>
</template>
<script>
import { mapGetters } from 'vuex'
import { cuentaBancariaResource } from '@/services/cuentaBancariaResource'
import { codigoRetencionResource } from '@/services/codigoRetencionResource'

export default {
  props: {
    item: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      isBusyBuscandoCuentaBancaria: false,
      optionsCuentaBancaria: [],
      nameModulo: 'ABM_PARAMETROS_DDJJ',
      isWorking: false,
      itemForm: {
        nombre: null,
        nombreCorto: null,
        descripcion: null,
        cuentaBancaria: {
          id: null,
          numeroCuenta: null,
        },
        habilitado: null,
      },
      overlayConfig: {
        rounded: 'sm',
        variant: 'transparent',
        opacity: '0.8',
        blur: '5px',
        message: '',
      },
    }
  },
  computed: {
    ...mapGetters(
      {
        permisosVer: 'getPermisosVer',
        permisosDel: 'getPermisosDel',
        permisosIns: 'getPermisosIns',
        permisosUpd: 'getPermisosUpd',
      },
    ),
  },
  async created() {
    if (this.item === null) {
      this.itemForm.habilitado = true
    } else {
      this.itemForm = { ...this.item }
    }
    await this.getCuentasBancariasLista()
  },
  methods: {
    concatenatedText(option) {
      const entidadBancaria = option.entidadBancaria ? option.entidadBancaria.entidadBancaria : 'N/A'
      const dominioNombre = option.dominio ? option.dominio.nombre : 'N/A'
      const numeroCuenta = option.numeroCuenta ? option.numeroCuenta : 'N/A'

      return `${entidadBancaria} / ${dominioNombre} / ${numeroCuenta}`
    },
    cancelar() {
      this.$emit('cancelar')
    },
    validationItemsForm() {
      this.$refs.abmCodigoRetencionForm.validate().then(success => {
        if (success) {
          this.actionItem()
        }
      })
    },
    actionItem() {
      if (this.itemForm.id != null) {
        this.update()
      } else {
        this.save()
      }
    },
    save() {
      this.overlayConfig.message = 'Guardando ...'
      this.isWorking = true
      codigoRetencionResource().saveCodigoRetencion(this.itemForm).then(res => {
        this.$emit('guardar', res)
      }).catch(error => {
        console.error(error)
      })
        .finally(() => {
          this.isWorking = false
          this.overlayConfig.message = ''
        })
    },
    update() {
      this.overlayConfig.message = 'Guardando ...'
      this.isWorking = true
      codigoRetencionResource().updateCodigoRetencion(this.itemForm.id, this.itemForm).then(res => {
        this.$emit('guardar', res)
      }).catch(error => {
        console.error(error)
      })
        .finally(() => {
          this.isWorking = false
          this.overlayConfig.message = ''
        })
    },
    getCuentasBancariasLista() {
      this.isBusyBuscandoEntidadBancaria = true
      cuentaBancariaResource().findAllByIsActivoCuentasBancarias().then(result => {
        this.optionsCuentaBancaria = result
      }).catch(error => {
        console.error('error:', error)
      })
        .finally(() => {
          this.isBusyBuscandoCuentaBancaria = false
        })
    },
  },
}
</script>
