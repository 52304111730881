import axios from 'axios'
import {
  getReglasAplicacionRetenciones,
  getByIsActivoReglasAplicacionRetenciones,
  postReglasAplicacionRetenciones,
  putReglasAplicacionRetenciones,
} from '@/utils/paths'

const pathGetReglasAplicacionRetenciones = getReglasAplicacionRetenciones
const pathGetByIsActivoReglasAplicacionRetenciones = getByIsActivoReglasAplicacionRetenciones
const pathPostReglasAplicacionRetenciones = postReglasAplicacionRetenciones
const pathPutReglasAplicacionRetenciones = putReglasAplicacionRetenciones

export function reglasAplicacionRetencionesResource() {
  function findAllReglasAplicacionRetenciones() {
    return new Promise((resolve, reject) => {
      axios
        .get(pathGetReglasAplicacionRetenciones)
        .then(results => {
          resolve(results.data)
        })
        .catch(e => {
          reject(e.response)
        })
    })
  }
  function findAllByIsActivoReglasAplicacionRetenciones() {
    return new Promise((resolve, reject) => {
      axios
        .get(pathGetByIsActivoReglasAplicacionRetenciones)
        .then(results => {
          resolve(results.data)
        })
        .catch(e => {
          reject(e.response)
        })
    })
  }
  function saveReglasAplicacionRetenciones(ReglasAplicacionRetencionesDto) {
    return new Promise((resolve, reject) => {
      axios
        .post(pathPostReglasAplicacionRetenciones, ReglasAplicacionRetencionesDto)
        .then(results => {
          resolve(results.data)
        })
        .catch(e => {
          reject(e.response)
        })
    })
  }
  function updateReglasAplicacionRetenciones(id, ReglasAplicacionRetencionesDto) {
    return new Promise((resolve, reject) => {
      axios
        .put(pathPutReglasAplicacionRetenciones + id, ReglasAplicacionRetencionesDto)
        .then(results => {
          resolve(results.data)
        })
        .catch(e => {
          reject(e.response)
        })
    })
  }
  return {
    findAllReglasAplicacionRetenciones,
    findAllByIsActivoReglasAplicacionRetenciones,
    saveReglasAplicacionRetenciones,
    updateReglasAplicacionRetenciones,
  }
}
