<template>
  <div>
    <b-card>
      <h4>{{ titulo }}</h4>
      <reglas-aplicacion-retenciones
        v-if="editando"
        :item="selectedItem"
        @cancelar="cancelar"
        @guardar="guardar"
      />
      <div v-if="!editando">
        <b-row>
          <b-col
            md="12"
            class="text-right"
          >
            <b-button
              v-if="permisosIns.includes(nameModulo)"
              id="btnAddReglasAplicacionRetenciones"
              v-b-tooltip.hover
              squared
              style="float: right;"
              size="sm"
              title="Nueva regla de aplicación de retenciones"
              variant="success"
              @click="() => addItem()"
            >
              <feather-icon icon="PlusIcon" />
            </b-button>
          </b-col>
        </b-row>
        <b-table
          id="tblEmpCate"
          :busy="isBussy"
          :items="options"
          :fields="fieldsOptions"
          outlined
          show-empty
          small
          hover
          sticky-header="70vh"
          :no-border-collapse="true"
          empty-text="No se encontraron resultados para esta búsqueda"
          @row-dblclicked="editarItem"
        >
          <template v-slot:table-busy>
            <div class="text-center">
              <b-spinner />
            </div>
          </template>
          <template #cell(habilitado)="row">
            <small>
              {{ row.item.habilitado === true ? 'Activo' : 'Inactivo' }}
            </small>
          </template>
          <template #cell(vigenciaDesde)="row">
            <small>
              {{
                row.item.vigenciaDesde
                  ? formatDateForList(row.item.vigenciaDesde, 'DD/MM/YYYY')
                  : 'N/A'
              }}
            </small>
          </template>
          <template #cell(vigenciaHasta)="row">
            <small>
              {{
                row.item.vigenciaHasta
                  ? formatDateForList(row.item.vigenciaHasta, 'DD/MM/YYYY')
                  : 'N/A'
              }}
            </small>
          </template>
          <template #cell(estadoLaboral.nombre)="row">
            <small>
              {{ row.item.estadoLaboral && row.item.estadoLaboral.nombre ? row.item.estadoLaboral.nombre : 'N/A' }}
            </small>
          </template>
          <template #cell(importeFijo)="row">
            <small>
              {{ row.item.importeFijo ? formatNumber(row.item.importeFijo) : 'N/A' }}
            </small>
          </template>

          <template #cell(porcentaje)="row">
            <small>
              {{ row.item.porcentaje ? formatNumber(row.item.porcentaje) : 'N/A' }}
            </small>
          </template>
          <template #cell(actions)="row">
            <div
              class="btn-group"
              role="group"
              aria-label="Basic example"
            >
              <b-button
                v-if="permisosUpd.includes(nameModulo)"
                v-b-tooltip.hover.righttop="'Editar item'"
                size="sm"
                @click="editarItem(row.item, row.index, $event.target)"
              >
                <font-awesome-icon icon="fa-regular fa-pen-to-square" />
              </b-button>
            </div>
          </template>
        </b-table>
      </div>

    </b-card>
  </div>
</template>
<script>
import { mapGetters } from 'vuex'
import { reglasAplicacionRetencionesResource } from '@/services/reglasAplicacionRetencionesResource'
import ReglasAplicacionRetenciones from '@/components/Ambs/ReglasAplicacionRetenciones/ReglasAplicacionRetenciones.vue'

export default {
  components: {
    ReglasAplicacionRetenciones,
  },
  data() {
    return {
      editando: false,
      options: null,
      nameModulo: 'ABM_PARAMETROS_DDJJ',
      fieldsOptions: [
        {
          key: 'nombre', label: 'Nombre',
        },
        {
          key: 'codigoRetencion.nombre', label: 'Código Retención',
        },
        {
          key: 'convenioTrabajo.nombre', label: 'Convenio',
        },
        {
          key: 'estadoLaboral.nombre', label: 'Estado laboral',
        },
        {
          key: 'provincia.nombre', label: 'Provincia',
        },
        {
          key: 'vigenciaDesde', label: 'Vigencia desde',
        },
        {
          key: 'vigenciaHasta', label: 'Vigencia hasta',
        },
        {
          key: 'importeFijo', label: 'Importe fijo',
        },
        {
          key: 'porcentaje', label: 'Porcentaje',
        },
        {
          key: 'habilitado', label: 'Estado',
        },
        { key: 'actions', label: 'Acciones', thStyle: { width: '10%' } },
      ],
      isBussy: false,
    }
  },
  computed: {
    titulo() {
      let titulo = 'Gestión reglas de aplicación de retenciones'
      if (this.editando) {
        if (this.selectedItem !== null) {
          titulo = 'Edición regla de aplicación de retenciones'
        } else {
          titulo = 'Nueva regla de aplicación de retenciones'
        }
      }
      return titulo
    },
    ...mapGetters(
      {
        permisosVer: 'getPermisosVer',
        permisosDel: 'getPermisosDel',
        permisosIns: 'getPermisosIns',
        permisosUpd: 'getPermisosUpd',
      },
    ),
  },
  created() {
    this.getLista()
  },
  methods: {
    addItem() {
      this.selectedItem = null
      this.editando = true
    },
    editarItem(item) {
      this.selectedItem = item
      this.editando = true
    },
    getLista() {
      this.isBussy = true
      reglasAplicacionRetencionesResource().findAllReglasAplicacionRetenciones().then(result => {
        this.options = result
      }).catch(error => {
        console.error('error:', error)
      })
        .finally(() => {
          this.isBussy = false
        })
    },
    formatNumber(value) {
      if (value !== null && value !== undefined) {
        return value.toString().replace('.', ',')
      }
      return 'N/A'
    },
    cancelar() {
      this.editando = false
      this.getLista()
    },
    guardar() {
      this.getLista()
      this.editando = false
    },
  },
}
</script>
