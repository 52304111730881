<template>
  <div>
    <validation-observer ref="abmParametrosDdjjForm">
      <b-form>
        <b-overlay
          :show="isWorking"
          :rounded="overlayConfig.rounded"
          :variant="overlayConfig.variant"
          :opacity="overlayConfig.opacity"
          :blur="overlayConfig.blur"
        >
          <template #overlay>
            <div class="mt-2">
              <div style="text-align: center">
                <span class="spinner-border " />
              </div>
              <div style="text-align: center">
                <small>{{ overlayConfig.message }}</small>
              </div>
            </div>
          </template>
          <b-card
            v-for="(param, index) in parametrosList"
            :key="index"
          >
            <b-row v-if="param.parametro !== 'TIPO DE PRESENTACIÓN'">
              <b-col md="6">
                <b-form-group
                  id="frmParametro"
                  label="Parámetro"
                >
                  {{ param.parametro }}
                </b-form-group>
              </b-col>
              <b-col md="6">
                <b-form-group>
                  <font-awesome-icon
                    v-b-tooltip.hover.righttop="param.descripcion"
                    icon="fa-solid fa-question-circle"
                    class="mr-2"
                  />
                </b-form-group>
              </b-col>
            </b-row>

            <b-row v-if="param.parametro === 'ESTADO LABORAL TRABAJADORES ACTIVOS' || param.parametro === 'ESTADO LABORAL TRABAJADORES INACTIVOS' || param.estadoLaboral !== null">
              <b-col md="6">
                <b-form-group
                  id="frmValor"
                  label="Valor"
                >
                  <validation-provider
                    name="valor"
                    rules="required"
                  >
                    <div slot-scope="{ errors, failedRules }">

                      <small v-if="isBusyBuscandoEstadoLaboral">
                        <span
                          v-if="isBusyBuscandoEstadoLaboral"
                          class="spinner-border spinner-border-sm"
                          aria-hidden="true"
                        /> Buscando valor
                      </small>
                      <b-form-select
                        v-if="!isBusyBuscandoEstadoLaboral"
                        id="selectEstadoLaboral"
                        v-model="param.estadoLaboral.id"
                        v-b-tooltip.hover="'Seleccione el valor'"
                        :state="errors.length > 0 ? false : null"
                        :options="optionsEstadoLaboral"
                        value-field="id"
                        text-field="nombre"
                        :aria-busy="isBusyBuscandoEstadoLaboral ? 'true' : 'false'"
                        :disabled="isBusyBuscandoEstadoLaboral"
                        rounded
                      >
                        <template #first>
                          <b-form-select-option
                            v-if="!isBusyBuscandoEstadoLaboral"
                            :value="null"
                            disabled
                          >
                            Seleccione
                          </b-form-select-option>
                        </template>
                      </b-form-select>
                      <small
                        v-if="failedRules.required && !isBusyBuscandoEstadoLaboral"
                        class="text-danger"
                      >
                        valor obligatorio.
                      </small>
                    </div>
                  </validation-provider>
                </b-form-group>
              </b-col>
            </b-row>

            <b-row v-if="param.parametro === 'MONTO BASE PARA PROCESO DE DDJJ'">
              <b-col md="6">
                <b-form-group
                  id="frmValor"
                  label="Valor"
                >
                  <validation-provider
                    name="valor"
                    rules="required"
                  >
                    <div slot-scope="{ errors, failedRules }">

                      <small v-if="isBusyBuscandoMontoBaseParaProceso">
                        <span
                          v-if="isBusyBuscandoMontoBaseParaProceso"
                          class="spinner-border spinner-border-sm"
                          aria-hidden="true"
                        /> Buscando valor
                      </small>
                      <b-form-select
                        v-if="!isBusyBuscandoMontoBaseParaProceso"
                        id="selectMontoBaseParaProceso"
                        v-model="param.dominio.id"
                        v-b-tooltip.hover="'Seleccione el valor'"
                        :state="errors.length > 0 ? false : null"
                        :options="optionsMontoBaseParaProceso"
                        value-field="id"
                        text-field="nombre"
                        :aria-busy="isBusyBuscandoMontoBaseParaProceso ? 'true' : 'false'"
                        :disabled="isBusyBuscandoMontoBaseParaProceso"
                        rounded
                      >
                        <template #first>
                          <b-form-select-option
                            v-if="!isBusyBuscandoMontoBaseParaProceso"
                            :value="null"
                            disabled
                          >
                            Seleccione
                          </b-form-select-option>
                        </template>
                      </b-form-select>
                      <small
                        v-if="failedRules.required && !isBusyBuscandoMontoBaseParaProceso"
                        class="text-danger"
                      >
                        valor obligatorio.
                      </small>
                    </div>
                  </validation-provider>
                </b-form-group>
              </b-col>
            </b-row>
            <b-row v-if="param.parametro === 'PLAZO DE ALMACENAMIENTO DE ARCHIVOS'">
              <b-col md="6">
                <b-form-group label="Plazo de Almacenamiento (años)">
                  <validation-provider
                    name="valor"
                    :rules="getPlazoAlmacenamientoRules()"
                  >
                    <div slot-scope="{ errors, failedRules }">
                      <b-form-input
                        id="txtValor"
                        v-model="param.valor"
                        :state="errors.length > 0 ? false : null"
                        type="number"
                        min="minimo"
                        max="maximo"
                      />
                      <small
                        v-if="failedRules.required"
                        class="text-danger"
                      >Valor obligatorio.</small>
                      <small
                        v-if="failedRules.max_value"
                        class="text-danger"
                      >Valor excedido (máximo {{ maximo }}).</small>
                      <small
                        v-if="failedRules.min_value"
                        class="text-danger"
                      >Valor por debajo del mínimo (mínimo {{ minimo }}).</small>
                    </div>
                  </validation-provider>
                </b-form-group>
              </b-col>

            </b-row>

            <b-row v-if="param.parametro === 'RELACIÓN MONTO NO REMUNERATIVO'">
              <b-col md="6">
                <b-form-group label="Relación Monto No Remunerativo (%)">
                  <validation-provider
                    name="valor"
                    rules="required|max_value:100|min_value:0"
                  >
                    <div slot-scope="{ errors, failedRules }">
                      <b-form-input
                        id="txtValor"
                        v-model="param.valor"
                        :state="errors.length > 0 ? false : null"
                        type="number"
                        min="0"
                        max="100"
                      />
                      <small
                        v-if="failedRules.required"
                        class="text-danger"
                      >Valor obligatorio.</small>
                      <small
                        v-if="failedRules.max_value"
                        class="text-danger"
                      >Valor excedido (máximo 100).</small>
                      <small
                        v-if="failedRules.min_value"
                        class="text-danger"
                      >Valor por abajo del mínimo (mínimo 0).</small>
                    </div>
                  </validation-provider>
                </b-form-group>
              </b-col>
            </b-row>
            <hr style="margin-bottom: 0;">
          </b-card>
        </b-overlay>
        <b-row>
          <div>
            <b-col md="12">
              <b-button
                id="btnGCancelarParametrosDdjj"
                v-b-tooltip.hover
                title="Volver"
                variant="light"
                squared
                :disabled="isWorking"
                @click="() => cancelar()"
              >
                <feather-icon icon="ArrowLeftIcon" />
              </b-button>
            </b-col>
          </div>
          <div class="align-self-end ml-auto">
            <b-col md="12">
              <b-button
                v-if="permisosIns.includes(nameModulo)"
                id="btnGuardarParametrosDdjj"
                v-b-tooltip.hover
                variant="primary"
                squared
                title="Guardar"
                :disabled="isWorking"
                type="submit"
                @click.prevent="validationItemsForm"
              >
                <font-awesome-icon
                  v-if="!isWorking"
                  icon="fa-solid fa-floppy-disk"
                />
                <span
                  v-if="isWorking"
                  class="spinner-border spinner-border-sm"
                  aria-hidden="true"
                />
              </b-button>
            </b-col>
          </div>
        </b-row>
      </b-form>
    </validation-observer>
  </div>
</template>
<script>
import { mapGetters } from 'vuex'
import { convenioTrabajoResource } from '@/services/convenioTrabajoResource'
import { parametrosDdjjResource } from '@/services/parametrosDdjjResource'
import { estadoLaboralResource } from '@/services/estadoLaboralResource'

export default {
  props: {
    item: {
      type: Object,
      default: null,
    },
    parametrosList: {
      type: Array,
      default: null,
    },
  },
  data() {
    return {
      optionsCategoria: null,
      nameModulo: 'ABM_PARAMETROS_DDJJ',
      isBussy: false,
      isWorking: false,
      isBusyBuscandoConvenioLaboral: false,
      isBusyBuscandoTipoPresentacion: false,
      isBusyBuscandoMontoBaseParaProceso: false,
      isBusyBuscandoPlazoAlmacenamientoArchivos: false,
      isBusyBuscandoEstadoLaboral: false,
      optionsEstadoLaboral: [],
      optionsConvenioTrabajo: [],
      optionsTipoPresentacion: [],
      optionsMontoBaseParaProceso: [],
      maximo: null,
      minimo: null,
      itemForm: null,
      parametro: {
        id: null,
        parametro: null,
        descripcion: null,
        valor: null,
        dominio: {
          id: null,
          nombre: null,
        },
        estadoLaboral: {
          id: null,
          nombre: null,
        },
        convenioTrabajo: {
          id: null,
          nombre: null,
        },
        fechaCreacion: null,
      },
      overlayConfig: {
        rounded: 'sm',
        variant: 'transparent',
        opacity: '0.8',
        blur: '5px',
        message: '',
      },
    }
  },
  computed: {
    ...mapGetters(
      {
        permisosVer: 'getPermisosVer',
        permisosDel: 'getPermisosDel',
        permisosIns: 'getPermisosIns',
        permisosUpd: 'getPermisosUpd',
      },
    ),
  },
  async created() {
    await this.iniciar()
  },
  methods: {
    iniciar() {
      this.getConvenioTrabajoLista()
      this.getTipoPresentacionLista()
      this.getMontoBaseParaProcesoLista()
      this.getEstadoLaboralLista()
      this.getPlazoAlmacenamientoArchivosLista()
    },
    toast(type, title, html) {
      this.customizeSweetAlert2()
      const htmlText = `<p style="color:${this.swalConfig.textColor}">${html}</p>`
      this.$swal({
        icon: type,
        title,
        html: htmlText,
        background: this.swalConfig.background,
        confirmButtonText: 'Aceptar',
        allowOutsideClick: false,
        customClass: {
          confirmButton: 'btn btn-primary square',
          cancelButton: 'btn btn-danger square',
        },
      })
    },
    cancelar() {
      this.$emit('cancelar')
    },
    getPlazoAlmacenamientoRules() {
      return `required|max_value:${this.maximo}|min_value:${this.minimo}`
    },
    async validationItemsForm() {
      if (await this.validateParametrosList()) {
        this.$refs.abmParametrosDdjjForm.validate().then(success => {
          if (success) {
            this.updateAll()
          }
        })
      } else {
        this.toast('warning', '', 'Se debe revisar los datos ingresados.')
      }
    },
    validateParametrosList() {
      // eslint-disable-next-line no-plusplus
      for (let i = 0; i < this.parametrosList.length; i++) {
        const parametro = this.parametrosList[i]

        if (parametro.parametro === 'RELACIÓN MONTO NO REMUNERATIVO') {
          // eslint-disable-next-line no-restricted-globals
          if (parametro.valor === '' || isNaN(parametro.valor) || parametro.valor < 0 || parametro.valor > 100) {
            console.error('Error en el parámetro:', parametro.parametro)
            return false
          }
        } else if (parametro.parametro === 'PLAZO DE ALMACENAMIENTO DE ARCHIVOS') {
          // eslint-disable-next-line no-restricted-globals
          if (parametro.valor === '' || isNaN(parametro.valor) || parametro.valor < this.minimo || parametro.valor > this.maximo) {
            console.error('Error en el parámetro:', parametro.parametro)
            return false
          }
        }
      }

      return true
    },
    async updateAll() {
      this.overlayConfig.message = 'Guardando ...'
      this.isWorking = true

      try {
        // eslint-disable-next-line no-restricted-syntax
        for (const param of this.parametrosList) {
          // eslint-disable-next-line no-await-in-loop
          await this.findByNombres(param)
          // eslint-disable-next-line no-await-in-loop
          await this.update(param)
        }
        await this.itemsActualizados()
      } catch (error) {
        console.error(error)
      } finally {
        this.isWorking = false
        this.overlayConfig.message = ''
      }
    },
    findByNombres(param) {
      if (param.dominio !== null) {
        if (param.dominio.dominio === 'MONTO_BASE_PARA_PROCESO') {
          this.optionsMontoBaseParaProceso.forEach(element => {
            if (element.id === param.dominio.id) {
              // eslint-disable-next-line no-param-reassign
              param.dominio.nombre = element.nombre
            }
          })
        } else {
          this.optionsTipoPresentacion.forEach(element => {
            if (element.id === param.dominio.id) {
              // eslint-disable-next-line no-param-reassign
              param.dominio.nombre = element.nombre
            }
          })
        }
      } else if (param.estadoLaboral !== null) {
        this.optionsEstadoLaboral.forEach(element => {
          if (element.id === param.estadoLaboral.id) {
            // eslint-disable-next-line no-param-reassign
            param.estadoLaboral.nombre = element.nombre
          }
        })
      }
    },
    update(param) {
      this.overlayConfig.message = 'Guardando ...'
      this.isWorking = true
      parametrosDdjjResource().updateParametroDdjj(param.id, param).then(res => {
        const index = this.parametrosList.findIndex(item => item.id === param.id)
        if (index !== -1) {
          this.$set(this.parametrosList, index, res)
          this.$emit('guardar', res)
        }
      }).catch(error => {
        console.error(error)
      })
        .finally(() => {
          this.isWorking = false
          this.overlayConfig.message = ''
        })
    },
    itemsActualizados() {
      parametrosDdjjResource().findParametrosActualizados().then(() => {
        this.$emit('guardar')
      }).catch(error => {
        console.error(error)
      })
        .finally(() => {
          this.isWorking = false
          this.overlayConfig.message = ''
        })
    },
    getConvenioTrabajoLista() {
      this.isBusyBuscandoConvenioLaboral = true
      convenioTrabajoResource().findAllByIsActivoConvenioLaboral().then(result => {
        this.optionsConvenioTrabajo = result
      }).catch(error => {
        console.error('error:', error)
      })
        .finally(() => {
          this.isBusyBuscandoConvenioLaboral = false
        })
    },
    getEstadoLaboralLista() {
      this.isBusyBuscandoEstadoLaboral = true
      estadoLaboralResource().findEstadoLaboralByConvenio(this.item.id).then(result => {
        this.optionsEstadoLaboral = result
      }).catch(error => {
        console.error('error:', error)
      })
        .finally(() => {
          this.isBusyBuscandoEstadoLaboral = false
        })
    },
    getTipoPresentacionLista() {
      this.isBusyBuscandoTipoPresentacion = true
      parametrosDdjjResource().findAllByTipoPresentacion().then(result => {
        this.optionsTipoPresentacion = result
      }).catch(error => {
        console.error('error:', error)
      })
        .finally(() => {
          this.isBusyBuscandoTipoPresentacion = false
        })
    },
    getMontoBaseParaProcesoLista() {
      this.isBusyBuscandoMontoBaseParaProceso = true
      parametrosDdjjResource().findAllByMontoBaseParaProceso().then(result => {
        this.optionsMontoBaseParaProceso = result
      }).catch(error => {
        console.error('error:', error)
      })
        .finally(() => {
          this.isBusyBuscandoMontoBaseParaProceso = false
        })
    },
    getPlazoAlmacenamientoArchivosLista() {
      this.isBusyBuscandoPlazoAlmacenamientoArchivos = true
      parametrosDdjjResource().findAllByPlazoAlmacenamientoArchivos().then(result => {
        this.minimo = Number(result[0].nombre)
        this.maximo = Number(result[1].nombre)
      }).catch(error => {
        console.error('error:', error)
      })
        .finally(() => {
          this.isBusyBuscandoPlazoAlmacenamientoArchivos = false
        })
    },
  },
}
</script>
