<template>
  <div>
    <validation-observer ref="abmReglasAplicacionRetencionesForm">
      <b-form>
        <b-overlay
          :show="isWorking"
          :rounded="overlayConfig.rounded"
          :variant="overlayConfig.variant"
          :opacity="overlayConfig.opacity"
          :blur="overlayConfig.blur"
        >
          <template #overlay>
            <div class="mt-2">
              <div style="text-align: center">
                <span class="spinner-border " />
              </div>
              <div style="text-align: center">
                <small>{{ overlayConfig.message }}</small>
              </div>
            </div>
          </template>
          <b-card>
            <b-row>
              <b-col md="3">
                <b-form-group
                  id="frmNombreReglasAplicacionRetenciones"
                  label="Nombre"
                >
                  <validation-provider
                    name="nombreReglasAplicacionRetenciones"
                    rules="required|max:255"
                  >
                    <div slot-scope="{ errors, failedRules }">
                      <b-form-input
                        id="txtNombreReglasAplicacionRetenciones"
                        v-model="itemForm.nombre"
                        :state="errors.length > 0 ? false : null"
                        autocomplete="off"
                      />
                      <small
                        v-if="failedRules.required"
                        class="text-danger"
                      >Nombre obligatorio.</small>
                      <small
                        v-if="failedRules.max"
                        class="text-danger"
                      >Máximo 255 caracteres.</small>
                    </div>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col md="3">
                <b-form-group
                  id="frmCodigoRetencion"
                  label="Código Retención"
                >
                  <validation-provider
                    name="codigoRetencion"
                    rules="required"
                  >
                    <div slot-scope="{ errors, failedRules }">

                      <small v-if="isBusyBuscandoCodigoRetencion">
                        <span
                          v-if="isBusyBuscandoCodigoRetencion"
                          class="spinner-border spinner-border-sm"
                          aria-hidden="true"
                        /> Buscando código retención
                      </small>
                      <b-form-select
                        v-if="!isBusyBuscandoCodigoRetencion"
                        id="selectCodigoRetencion"
                        v-model="itemForm.codigoRetencion.id"
                        v-b-tooltip.hover="'Seleccione el código retención'"
                        :state="errors.length > 0 ? false : null"
                        :options="optionsCodigoRetencion"
                        value-field="id"
                        text-field="nombre"
                        :aria-busy="isBusyBuscandoCodigoRetencion ? 'true' : 'false'"
                        :disabled="isBusyBuscandoCodigoRetencion"
                        rounded
                      >
                        <template #first>
                          <b-form-select-option
                            v-if="!isBusyBuscandoCodigoRetencion"
                            :value="null"
                            disabled
                          >
                            Seleccione
                          </b-form-select-option>
                        </template>
                      </b-form-select>
                      <small
                        v-if="failedRules.required && !isBusyBuscandoCodigoRetencion"
                        class="text-danger"
                      >
                        Código Retención obligatorio.
                      </small>
                    </div>
                  </validation-provider>
                </b-form-group>
              </b-col>

              <b-col md="6">
                <b-form-group
                  id="frmConvenioTrabajo"
                  label="Convenio de trabajo"
                >
                  <validation-provider
                    name="convenioTrabajo"
                    rules="required"
                  >
                    <div slot-scope="{ errors, failedRules }">

                      <small v-if="isBusyBuscandoConvenioTrabajo">
                        <span
                          v-if="isBusyBuscandoConvenioTrabajo"
                          class="spinner-border spinner-border-sm"
                          aria-hidden="true"
                        /> Buscando convenio de trabajo
                      </small>
                      <b-form-select
                        v-if="!isBusyBuscandoConvenioTrabajo"
                        id="selectConvenioTrabajo"
                        v-model="itemForm.convenioTrabajo.id"
                        v-b-tooltip.hover="'Seleccione el convenio de trabajo'"
                        :state="errors.length > 0 ? false : null"
                        :options="optionsConvenioTrabajo"
                        value-field="id"
                        text-field="nombre"
                        :aria-busy="isBusyBuscandoConvenioTrabajo ? 'true' : 'false'"
                        :disabled="isBusyBuscandoConvenioTrabajo"
                        rounded
                      >
                        <template #first>
                          <b-form-select-option
                            v-if="!isBusyBuscandoConvenioTrabajo"
                            :value="null"
                            disabled
                          >
                            Seleccione
                          </b-form-select-option>
                        </template>
                      </b-form-select>
                      <small
                        v-if="failedRules.required && !isBusyBuscandoConvenioTrabajo"
                        class="text-danger"
                      >
                        Convenio de trabajo obligatorio.
                      </small>
                    </div>
                  </validation-provider>
                </b-form-group>
              </b-col>
            </b-row>
            <b-row>
              <b-col md="6">
                <b-form-group
                  id="frmEstadoLaboral"
                  label="Estado laboral"
                >
                  <validation-provider
                    name="estadoLaboral"
                    rules="required"
                  >
                    <div slot-scope="{ errors, failedRules }">

                      <small v-if="isBusyBuscandoEstadoLaboral">
                        <span
                          v-if="isBusyBuscandoEstadoLaboral"
                          class="spinner-border spinner-border-sm"
                          aria-hidden="true"
                        /> Buscando estado laboral
                      </small>
                      <b-form-select
                        v-if="!isBusyBuscandoEstadoLaboral"
                        id="selectEstadoLaboral"
                        v-model="itemForm.estadoLaboral.id"
                        v-b-tooltip.hover="'Seleccione el estado laboral'"
                        :state="errors.length > 0 ? false : null"
                        :options="optionsEstadoLaboral"
                        value-field="id"
                        text-field="nombre"
                        :aria-busy="isBusyBuscandoEstadoLaboral ? 'true' : 'false'"
                        :disabled="isBusyBuscandoEstadoLaboral"
                        rounded
                      >
                        <template #first>
                          <b-form-select-option
                            v-if="!isBusyBuscandoEstadoLaboral"
                            :value="null"
                            disabled
                          >
                            Seleccione
                          </b-form-select-option>
                        </template>
                      </b-form-select>
                      <small
                        v-if="failedRules.required && !isBusyBuscandoEstadoLaboral"
                        class="text-danger"
                      >
                        Estado laboral obligatorio.
                      </small>
                    </div>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col md="6">
                <b-form-group
                  id="frmProvincia"
                  label="Provincia"
                >
                  <validation-provider
                    name="provincia"
                    rules="required"
                  >
                    <div slot-scope="{ errors, failedRules }">

                      <small v-if="isBusyBuscandoProvincia">
                        <span
                          v-if="isBusyBuscandoProvincia"
                          class="spinner-border spinner-border-sm"
                          aria-hidden="true"
                        /> Buscando provincias
                      </small>
                      <b-form-select
                        v-if="!isBusyBuscandoProvincia"
                        id="selectProvincia"
                        v-model="itemForm.provincia.id"
                        v-b-tooltip.hover="'Seleccione la provincia'"
                        :state="errors.length > 0 ? false : null"
                        :options="optionsProvincia"
                        value-field="id"
                        text-field="nombre"
                        :aria-busy="isBusyBuscandoProvincia ? 'true' : 'false'"
                        :disabled="isBusyBuscandoProvincia"
                        rounded
                      >
                        <template #first>
                          <b-form-select-option
                            v-if="!isBusyBuscandoProvincia"
                            :value="null"
                            disabled
                          >
                            Seleccione
                          </b-form-select-option>
                        </template>
                      </b-form-select>
                      <small
                        v-if="failedRules.required && !isBusyBuscandoProvincia"
                        class="text-danger"
                      >
                        Provincia obligatoria.
                      </small>
                    </div>
                  </validation-provider>
                </b-form-group>
              </b-col>
            </b-row>
            <b-row>
              <b-col md="6">
                <b-form-group
                  label-for="vigenciadesde"
                  label="Vigencia desde"
                >
                  <validation-provider
                    name="vigenciaDesde"
                    rules="min:8"
                  >
                    <div slot-scope="{ errors, failedRules }">
                      <cleave
                        id="date"
                        v-model="itemForm.vigenciaDesde"
                        class="form-control"
                        :state="errors.length > 0 ? false:null"
                        :raw="true"
                        :options="options.date"
                        autocomplete="off"
                        placeholder="Vigencia desde DD/MM/AAAA"
                      />
                      <small
                        v-if="failedRules.min"
                        class="text-danger"
                      >Vigencia Desde incompleta
                      </small>
                      <small
                        v-if="compararFechas(itemForm.vigenciaDesde, itemForm.vigenciaHasta)"
                        class="text-danger"
                      >Vigencia desde debe ser menor que Vigencia hasta.</small>
                    </div>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col md="6">
                <b-form-group
                  label-for="vigenciaHasta"
                  label="Vigencia hasta"
                >
                  <validation-provider
                    name="vigenciaHasta"
                    rules="min:8"
                  >
                    <div slot-scope="{ errors, failedRules }">
                      <cleave
                        id="date"
                        v-model="itemForm.vigenciaHasta"
                        class="form-control"
                        :state="errors.length > 0 ? false : null"
                        :raw="true"
                        :options="options.date"
                        autocomplete="off"
                        placeholder="vigencia hasta DD/MM/AAAA"
                      />
                      <small
                        v-if="failedRules.min"
                        class="text-danger"
                      >Vigencia Hasta incompleta
                      </small>
                      <small
                        v-if="compararFechas(itemForm.vigenciaDesde, itemForm.vigenciaHasta)"
                        class="text-danger"
                      >Vigencia hasta debe ser mayor que vigencia desde.</small>
                    </div>
                  </validation-provider>
                </b-form-group>
              </b-col>
            </b-row>
            <b-row>
              <b-col md="6">
                <b-form-group
                  id="frmImporteFijo"
                  label="Importe fijo"
                >
                  <validation-provider
                    name="importeFijo"
                    rules="validarImporteFijo|min:0"
                  >
                    <div slot-scope="{ errors, failedRules }">
                      <input
                        id="txtImporteFijo"
                        v-model="formattedImporteFijo"
                        :state="errors.length > 0 ? false : null"
                        autocomplete="off"
                        type="text"
                        class="form-control"
                        @input="onInput"
                      >
                      <small
                        v-if="failedRules.validarImporteFijo"
                        class="text-danger"
                      >
                        Importe fijo debe ser un número válido.
                      </small>
                      <small
                        v-if="failedRules.min"
                        class="text-danger"
                      >
                        Importe fijo debe ser mayor a 0.
                      </small>
                    </div>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col md="6">
                <b-form-group
                  id="frmPorcentaje"
                  label="Porcentaje"
                >
                  <validation-provider
                    name="porcentaje"
                    rules="validarPorcentaje"
                  >
                    <div slot-scope="{ errors, failedRules }">
                      <input
                        id="txtPorcentaje"
                        v-model="formattedPorcentaje"
                        :state="errors.length > 0 ? false : null"
                        autocomplete="off"
                        type="text"
                        class="form-control"
                        @input="onInputPorcentaje"
                      >
                      <small
                        v-if="failedRules.validarPorcentaje"
                        class="text-danger"
                      >
                        Porcentaje debe ser un número válido entre 0 y 100.
                      </small>
                    </div>
                  </validation-provider>
                </b-form-group>
              </b-col>
            </b-row>
            <b-row>
              <b-col md="2">
                <b-form-group
                  id="frmChecknSoloAfiliados"
                  label="Solo Afiliados"
                >
                  <b-form-checkbox
                    v-model="itemForm.soloAfiliados"
                    v-b-tooltip.hover="'Activar / Desactivar'"
                    class="custom-control-success"
                    name="check-button"
                    switch
                  >
                    <small class="switch-icon-left">
                      <feather-icon icon="CheckIcon" />
                    </small>
                  </b-form-checkbox>
                </b-form-group>
              </b-col>
              <b-col md="2">
                <b-form-group
                  id="frmChecknReglaAplicacionRetenciones"
                  label="Estado"
                >
                  <b-form-checkbox
                    v-model="itemForm.habilitado"
                    v-b-tooltip.hover="'Activar / Desactivar'"
                    class="custom-control-success"
                    name="check-button"
                    switch
                  >
                    <small class="switch-icon-left">
                      <feather-icon icon="CheckIcon" />
                    </small>
                  </b-form-checkbox>
                </b-form-group>
              </b-col>
            </b-row>
          </b-card>
        </b-overlay>
        <hr>
        <b-row>
          <div>
            <b-col md="12">
              <b-button
                id="btnGCancelarReglasAplicacionRetenciones"
                v-b-tooltip.hover
                title="Volver"
                variant="light"
                squared
                :disabled="isWorking"
                @click="() => cancelar()"
              >
                <feather-icon icon="ArrowLeftIcon" />
              </b-button>
            </b-col>
          </div>
          <div class="align-self-end ml-auto">
            <b-col md="12">
              <b-button
                v-if="permisosIns.includes(nameModulo)"
                id="btnGuardarReglasAplicacionRetenciones"
                v-b-tooltip.hover
                variant="primary"
                squared
                title="Guardar"
                :disabled="isWorking"
                type="submit"
                @click.prevent="validationItemsForm"
              >
                <font-awesome-icon
                  v-if="!isWorking"
                  icon="fa-solid fa-floppy-disk"
                />
                <span
                  v-if="isWorking"
                  class="spinner-border spinner-border-sm"
                  aria-hidden="true"
                />
              </b-button>
            </b-col>
          </div>
        </b-row>
      </b-form>
    </validation-observer>
  </div>
</template>
<script>
import { mapGetters } from 'vuex'
import { convenioTrabajoResource } from '@/services/convenioTrabajoResource'
import { estadoLaboralResource } from '@/services/estadoLaboralResource'
import { provinciaResource } from '@/services/provinciaResource'
import { reglasAplicacionRetencionesResource } from '@/services/reglasAplicacionRetencionesResource'
import { codigoRetencionResource } from '@/services/codigoRetencionResource'
import Cleave from 'vue-cleave-component'
import { extend } from 'vee-validate'

extend('validarImporteFijo', {
  validate: value => {
    const customRegex = /^-?\d+(,\d+)?$/
    return customRegex.test(value)
  },
  message: 'Importe fijo inválido',
})

extend('validarPorcentaje', {

  validate: value => {
    const customRegex = /^-?\d+(?:[,]\d+)?(?:[Ee][-+]?\d+)?$/
    return customRegex.test(value)
  },
  message: 'Porcentaje inválido',
})
export default {
  components: {
    Cleave,
  },
  props: {
    item: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      isBusyBuscandoProvincia: false,
      isBusyBuscandoEstadoLaboral: false,
      isBusyBuscandoConvenioTrabajo: false,
      isBusyBuscandoCodigoRetencion: false,
      inputTimer: null,
      optionsProvincia: [],
      optionsEstadoLaboral: [],
      optionsConvenioTrabajo: [],
      optionsCodigoRetencion: [],
      nameModulo: 'ABM_PARAMETROS_DDJJ',
      isWorking: false,
      itemForm: {
        nombre: null,
        codigoRetencion: {
          id: null,
          nombre: null,
        },
        convenioTrabajo: {
          id: null,
          nombre: null,
        },
        estadoLaboral: {
          id: null,
          nombre: null,
        },
        provincia: {
          id: null,
          nombre: null,
        },
        soloAfiliados: null,
        vigenciaDesde: null,
        vigenciaHasta: null,
        importeFijo: null,
        porcentaje: null,
        habilitado: null,
      },
      options: {
        date: {
          date: true,
          delimiter: '/',
          datePattern: ['d', 'm', 'Y'],
        },
      },
      overlayConfig: {
        rounded: 'sm',
        variant: 'transparent',
        opacity: '0.8',
        blur: '5px',
        message: '',
      },
    }
  },
  computed: {
    ...mapGetters(
      {
        permisosVer: 'getPermisosVer',
        permisosDel: 'getPermisosDel',
        permisosIns: 'getPermisosIns',
        permisosUpd: 'getPermisosUpd',
      },
    ),
    formattedImporteFijo: {
      get() {
        return this.itemForm.importeFijo
      },
      set(value) {
        this.itemForm.importeFijo = value
      },
    },
    formattedPorcentaje: {
      get() {
        return this.itemForm.porcentaje
      },
      set(value) {
        this.itemForm.porcentaje = value
      },
    },
  },
  watch: {
    'itemForm.convenioTrabajo.id': {
      handler(newConvenioId, oldConvenioId) {
        if (newConvenioId !== oldConvenioId) {
          if (newConvenioId !== null) {
            this.getNewEstadoLaboralLista(newConvenioId)
          }
        }
      },
    },
  },
  async created() {
    await this.getCodigoRetencionLista()
    if (this.item === null) {
      this.itemForm.habilitado = true
      this.itemForm.soloAfiliados = true
    } else {
      this.itemForm = { ...this.item }
      await this.convertToDecimalWithComma()
      await this.getNewEstadoLaboralLista(this.itemForm.convenioTrabajo.id)
    }
    await this.getConvenioTrabajoLista()
    await this.getProvinciaLista()
  },
  methods: {
    cancelar() {
      this.$emit('cancelar')
    },
    onInput() {
      this.itemForm.porcentaje = null
      let value = this.itemForm.importeFijo
      value = value.replace(/[^\d,]/g, '')
      const parts = value.split(',')
      if (parts[0].length > 15) {
        value = `${parts[0].substring(0, 15)},${parts[1] || ''}`
      }
      if (parts.length === 2 && parts[1].length > 2) {
        value = `${parts[0]},${parts[1].substring(0, 2)}`
      }
      this.itemForm.importeFijo = value
    },
    onInputPorcentaje() {
      this.itemForm.importeFijo = null
      let value = this.itemForm.porcentaje
      value = value.replace(/[^\d,]/g, '')
      const parts = value.split(',')
      // eslint-disable-next-line radix
      const porcentaje = parseInt(parts[0])
      // eslint-disable-next-line radix
      const decimales = parseInt(parts[1])
      if ((porcentaje > 100) || (porcentaje === 100 && decimales > 0)) {
        this.itemForm.porcentaje = '100,00'
        return
      }
      if (parts[0].length > 3) {
        value = `${parts[0].substring(0, 3)},${parts[1] || ''}`
      }
      if (parts.length === 2 && parts[1].length > 2) {
        value = `${parts[0]},${parts[1].substring(0, 2)}`
      }
      this.itemForm.porcentaje = value

      clearTimeout(this.inputTimer)
      this.inputTimer = setTimeout(async () => {
        await this.formatPorcentaje()
      }, 2000)
    },
    formatPorcentaje() {
      let value = this.itemForm.porcentaje
      if (value !== null) {
        value = value.replace(/[^\d,]/g, '')
        const parts = value.split(',')
        if (parts[0].length > 1 && parts[0][0] === '0') {
          // eslint-disable-next-line no-param-reassign
          parts[0] = parts[0].substring(1)
        }
        if (parts[1] && parts[1].length === 1) {
          // eslint-disable-next-line no-param-reassign
          parts[1] += '0'
        }
        value = parts.join(',')
        this.itemForm.porcentaje = value
      }
    },
    compararFechas(vigenciaDesde, vigenciaHasta) {
      if (!vigenciaDesde || !vigenciaHasta) {
        return false
      }
      const fechaDesde = this.parsearFecha(vigenciaDesde)
      const fechaHasta = this.parsearFecha(vigenciaHasta)
      return fechaDesde > fechaHasta
    },
    parsearFecha(fecha) {
      const dia = Number(fecha.substr(0, 2))
      const mes = Number(fecha.substr(2, 2))
      const anio = Number(fecha.substr(4, 4))

      return new Date(anio, mes - 1, dia)
    },
    toast(type, title, html) {
      this.customizeSweetAlert2()
      const htmlText = `<p style="color:${this.swalConfig.textColor}">${html}</p>`
      this.$swal({
        icon: type,
        title,
        html: htmlText,
        background: this.swalConfig.background,
        confirmButtonText: 'Aceptar',
        allowOutsideClick: false,
        customClass: {
          confirmButton: 'btn btn-primary square',
          cancelButton: 'btn btn-danger square',
        },
      })
    },
    validationItemsForm() {
      this.$refs.abmReglasAplicacionRetencionesForm.validate().then(success => {
        if (success) {
          if (!this.compararFechas(this.itemForm.vigenciaDesde, this.itemForm.vigenciaHasta)) {
            this.actionItem()
          }
        }
      })
    },
    async actionItem() {
      const { porcentaje } = this.itemForm
      const { importeFijo } = this.itemForm
      if (!porcentaje && !importeFijo) {
        this.toast('warning', 'Campo incompleto', 'Debe ingresar un valor en el campo importe fijo o bien en el campo porcentaje')
      } else {
        await this.formatPorcentaje()
        if (this.itemForm.id != null) {
          this.update()
        } else {
          this.save()
        }
      }
    },
    save() {
      this.overlayConfig.message = 'Guardando ...'
      this.isWorking = true
      this.itemForm.vigenciaDesde = this.formatDateToJavaT(this.itemForm.vigenciaDesde, 'DDMMYYYY', 'DD-MM-YYYY HH:mm')
      this.itemForm.vigenciaHasta = this.formatDateToJavaT(this.itemForm.vigenciaHasta, 'DDMMYYYY', 'DD-MM-YYYY HH:mm')
      reglasAplicacionRetencionesResource().saveReglasAplicacionRetenciones(this.itemForm).then(res => {
        this.$emit('guardar', res)
      }).catch(error => {
        console.error(error)
      })
        .finally(() => {
          this.isWorking = false
          this.overlayConfig.message = ''
        })
    },
    update() {
      this.overlayConfig.message = 'Guardando ...'
      this.isWorking = true
      this.itemForm.vigenciaDesde = this.formatDateToJavaT(this.itemForm.vigenciaDesde, 'DDMMYYYY', 'DD-MM-YYYY HH:mm')
      this.itemForm.vigenciaHasta = this.formatDateToJavaT(this.itemForm.vigenciaHasta, 'DDMMYYYY', 'DD-MM-YYYY HH:mm')
      reglasAplicacionRetencionesResource().updateReglasAplicacionRetenciones(this.itemForm.id, this.itemForm).then(res => {
        this.$emit('guardar', res)
      }).catch(error => {
        console.error(error)
      })
        .finally(() => {
          this.isWorking = false
          this.overlayConfig.message = ''
        })
    },
    convertToDecimalWithComma() {
      if (this.itemForm.importeFijo != null) {
        this.itemForm.importeFijo = this.convertDotToComma(this.itemForm.importeFijo)
      }
      if (this.itemForm.porcentaje != null) {
        this.itemForm.porcentaje = this.convertDotToComma(this.itemForm.porcentaje)
      }
    },
    convertDotToComma(value) {
      if (typeof value === 'number') {
        const stringValue = value.toFixed(2).toString()
        return stringValue.replace('.', ',')
      } if (typeof value === 'string' || value instanceof String) {
        return value.replace('.', ',')
      }
      return value
    },
    getCodigoRetencionLista() {
      this.isBusyBuscandoCodigoRetencion = true
      codigoRetencionResource().findAllCodigoRetencion().then(result => {
        this.optionsCodigoRetencion = result
      }).catch(error => {
        console.error('error:', error)
      })
        .finally(() => {
          this.isBusyBuscandoCodigoRetencion = false
        })
    },
    getConvenioTrabajoLista() {
      this.isBusyBuscandoConvenioTrabajo = true
      convenioTrabajoResource().findAllByIsActivoConvenioLaboral().then(result => {
        this.optionsConvenioTrabajo = result
      }).catch(error => {
        console.error('error:', error)
      })
        .finally(() => {
          this.isBusyBuscandoConvenioTrabajo = false
        })
    },
    getNewEstadoLaboralLista(convenioId) {
      this.isBusyBuscandoEstadoLaboral = true
      this.optionsEstadoLaboral = []
      estadoLaboralResource().findEstadoLaboralByConvenio(convenioId).then(result => {
        if (this.itemForm.estadoLaboral.id !== null) {
          const estadoValido = result.some(estado => estado.id === this.itemForm.estadoLaboral.id)
          if (!estadoValido) {
            this.itemForm.estadoLaboral.id = null
          }
        }
        this.optionsEstadoLaboral = result
      }).catch(error => {
        console.error('error:', error)
      })
        .finally(() => {
          this.isBusyBuscandoEstadoLaboral = false
        })
    },
    getProvinciaLista() {
      this.isBusyBuscandoProvincia = true
      provinciaResource().getAllProvincia().then(result => {
        this.optionsProvincia = result
      }).catch(error => {
        console.error('error:', error)
      })
        .finally(() => {
          this.isBusyBuscandoProvincia = false
        })
    },
  },
}
</script>
<style scoped>
.form-control {
  display: block;
  width: 100%;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.form-control:focus {
  border-color: #80bdff;
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}
</style>
