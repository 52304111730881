<template>
  <div>
    <b-card>
      <b-row>
        <b-col md="3">
          <h4>{{ titulo }}</h4>
        </b-col>
        <b-col md="9">
          <b-form-select
            id="selectTipoAbm"
            v-model="parametroAbmSeleccionado"
            v-b-tooltip.hover="'Seleccione el parámetro'"
            rounded
          >
            <option
              v-for="obj in optionComponentesAbmParametrosDDJJ"
              :key="obj.id"
              :value="obj"
            >
              {{ obj.nombre }}
            </option>
            <template #first>
              <b-form-select-option
                :value="null"
                disabled
              >
                Seleccione
              </b-form-select-option>
            </template>
          </b-form-select>
        </b-col>
      </b-row>
    </b-card>
    <b-card v-if="parametroAbmSeleccionado != null">
      <component :is="parametroAbmSeleccionado.nombreComponente" />
    </b-card>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import CodigoDeRetencionLista from '@/components/Ambs/CodigoDeRetencion/CodigoDeRetencionLista.vue'
import CuentasBancariasLista from '@/components/Ambs/CuentasBancarias/CuentasBancariasLista.vue'
import EntidadesBancariasLista from '@/components/Ambs/EntidadesBancarias/EntidadesBancariasLista.vue'
import ReglasAplicacionRetencionesLista from '@/components/Ambs/ReglasAplicacionRetenciones/ReglasAplicacionRetencionesLista.vue'
import ParametrosLista from '@/components/Ambs/Parametros/ParametrosLista.vue'

export default {
  components: {
    // eslint-disable-next-line vue/no-unused-components
    CodigoDeRetencionLista,
    CuentasBancariasLista,
    ReglasAplicacionRetencionesLista,
    ParametrosLista,
    EntidadesBancariasLista,
  },
  data() {
    return {
      titulo: 'Parámetros DDJJ',
      parametroAbmSeleccionado: null,
      optionComponentesAbmParametrosDDJJ: [
        {
          id: 1,
          nombre: 'Parámetros',
          nombreComponente: 'ParametrosLista',
        },
        {
          id: 2,
          nombre: 'Código de retenciones',
          nombreComponente: 'CodigoDeRetencionLista',
        },
        {
          id: 3,
          nombre: 'Cuentas bancarias',
          nombreComponente: 'CuentasBancariasLista',
        },
        {
          id: 4,
          nombre: 'Entidades bancarias',
          nombreComponente: 'EntidadesBancariasLista',
        },
        {
          id: 5,
          nombre: 'Reglas de aplicación de retenciones',
          nombreComponente: 'ReglasAplicacionRetencionesLista',
        }],
    }
  },
  computed: {
    ...mapGetters(
      {
        permisosVer: 'getPermisosVer',
        permisosDel: 'getPermisosDel',
        permisosIns: 'getPermisosIns',
        permisosUpd: 'getPermisosUpd',
      },
    ),
  },
  created() {
    this.optionComponentesAbmParametrosDDJJ.sort((a, b) => a.nombre.localeCompare(b.nombre))
  },
}
</script>
