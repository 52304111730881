import axios from 'axios'
import {
  getEntidadBancaria,
  getByIsActivoEntidadBancaria,
  postEntidadBancaria,
  putEntidadBancaria,
} from '@/utils/paths'

const pathGetEntidadBancaria = getEntidadBancaria
const pathGetByIsActivoEntidadBancaria = getByIsActivoEntidadBancaria
const pathPostEntidadBancaria = postEntidadBancaria
const pathPutEntidadBancaria = putEntidadBancaria

export function entidadBancariaResource() {
  function findAllEntidadesBancarias() {
    return new Promise((resolve, reject) => {
      axios
        .get(pathGetEntidadBancaria)
        .then(results => {
          resolve(results.data)
        })
        .catch(e => {
          reject(e.response)
        })
    })
  }
  function findAllByIsActivoEntidadesBancarias() {
    return new Promise((resolve, reject) => {
      axios
        .get(pathGetByIsActivoEntidadBancaria)
        .then(results => {
          resolve(results.data)
        })
        .catch(e => {
          reject(e.response)
        })
    })
  }
  function saveEntidadBancaria(EntidadBancariaDto) {
    return new Promise((resolve, reject) => {
      axios
        .post(pathPostEntidadBancaria, EntidadBancariaDto)
        .then(results => {
          resolve(results.data)
        })
        .catch(e => {
          reject(e.response)
        })
    })
  }
  function updateEntidadBancaria(id, EntidadBancariaDto) {
    return new Promise((resolve, reject) => {
      axios
        .put(pathPutEntidadBancaria + id, EntidadBancariaDto)
        .then(results => {
          resolve(results.data)
        })
        .catch(e => {
          reject(e.response)
        })
    })
  }
  return {
    findAllEntidadesBancarias,
    findAllByIsActivoEntidadesBancarias,
    saveEntidadBancaria,
    updateEntidadBancaria,
  }
}
