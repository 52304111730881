<template>
  <div>
    <b-modal
      v-model="showEntidadBancariaModal"
      :title="title"
      no-close-on-backdrop
      size="lg"
      hide-header-close
      @close="closeModal"
    >
      <validation-observer ref="refEntidadBancaria">
        <b-row>
          <b-col md="6">
            <b-form-group
              id="frmEntidadBancaria"
              label="Entidad bancaria"
            >
              <validation-provider
                name="entidadBancaria"
                rules="required|max:255"
              >
                <div slot-scope="{ errors, failedRules }">
                  <b-form-input
                    id="txtEntidadBancaria"
                    v-model="entidadBancariaDto.entidadBancaria"
                    :state="errors.length > 0 ? false : null"
                    autocomplete="off"
                  />
                  <small
                    v-if="failedRules.required"
                    class="text-danger"
                  >Entidad bancaria obligatoria.</small>
                  <small
                    v-if="failedRules.max"
                    class="text-danger"
                  >Máximo 255 caracteres.</small>
                </div>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="2">
            <b-form-group
              id="frmChecknHabilitado"
              label="Habilitado"
            >
              <b-form-checkbox
                v-model="entidadBancariaDto.habilitado"
                v-b-tooltip.hover="'Activar / Desactivar'"
                class="custom-control-success"
                name="check-button"
                switch
              >
                <small class="switch-icon-left">
                  <feather-icon icon="CheckIcon" />
                </small>
              </b-form-checkbox>

            </b-form-group>
          </b-col>
        </b-row>
      </validation-observer>
      <template #modal-footer>
        <div
          v-if="openModeModal != 'view'"
          class="d-flex justify-content-between"
        >
          <b-button
            size="sm"
            squared
            variant="danger"
            @click="closeModal"
          >
            Cancelar
          </b-button>
          <b-button
            size="sm"
            squared
            variant="primary"
            @click="validationEntidadBancariaForm"
          >{{ tituloBotonAgregar }}
          </b-button>
        </div>
        <div
          v-if="openModeModal == 'view'"
          class="d-flex justify-content-between"
        >
          <b-button
            size="sm"
            squared
            variant="primary"
            @click="closeModal"
          >
            Cerrar
          </b-button>
        </div>
      </template>
    </b-modal>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { entidadBancariaResource } from '@/services/entidadBancariaResource'

export default {
  components: {
  },
  props: {
    item: {
      type: Object,
      default: null,
    },
    showEntidadBancariaModal: {
      type: Boolean,
      required: true,
    },
    openModeModal: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      nameModulo: 'ABM_AFILIADOS_DDJJ',
      isWorking: false,
      entidadBancariaDto: {
        id: null,
        entidadBancaria: null,
        obligatorio: null,
      },
      overlayConfig: {
        rounded: 'sm',
        variant: 'transparent',
        opacity: '0.8',
        blur: '5px',
        message: '',
      },
    }
  },
  computed: {
    ...mapGetters(
      {
        permisosVer: 'getPermisosVer',
        permisosDel: 'getPermisosDel',
        permisosIns: 'getPermisosIns',
        permisosUpd: 'getPermisosUpd',
      },
    ),
    tituloBotonAgregar() {
      return this.openModeModal === 'new' ? 'Agregar' : 'Modificar'
    },
    title() {
      return this.openModeModal === 'new' ? 'Nueva regla Adherente' : 'Edición regla Adherente'
    },
  },
  created() {
    if (this.item === null) {
      this.entidadBancariaDto.habilitado = true
    }
  },
  methods: {
    closeModal() {
      this.$emit('closeModalEntidadBancaria')
    },
    validationEntidadBancariaForm() {
      this.$refs.refEntidadBancaria.validate().then(success => {
        if (success) {
          this.save()
        }
      })
    },
    save() {
      this.overlayConfig.message = 'Guardando ...'
      this.isWorking = true
      entidadBancariaResource().saveEntidadBancaria(this.entidadBancariaDto).then(res => {
        this.$emit('agregarEntidadBancaria', res)
      }).catch(error => {
        console.error(error)
      })
        .finally(() => {
          this.isWorking = false
          this.overlayConfig.message = ''
        })
    },
  },
}
</script>
