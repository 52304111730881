import axios from 'axios'
import {
  getAllParametrosDdjj,
  getByFilterConvenioTrabajo,
  getConveniosTrabajo,
  putParametroDdjj,
  getByTipoPresentacion,
  getByMontoBaseParaProceso,
  getItemsActualizados,
  getByPlazoAlmacenamientoArchivos,
  getByTipoCuentas,
} from '@/utils/paths'

const pathGetAllParametrosDdjj = getAllParametrosDdjj
const pathGetByFilterConvenioTrabajo = getByFilterConvenioTrabajo
const pathGetConveniosTrabajo = getConveniosTrabajo
const pathPutParametroDdjj = putParametroDdjj
const pathGetTipoPresentacion = getByTipoPresentacion
const pathGetByMontoBaseParaProceso = getByMontoBaseParaProceso
const pathGetItemsActualizados = getItemsActualizados
const pathGetByPlazoAlmacenamientoArchivos = getByPlazoAlmacenamientoArchivos
const pathGetByTipoCuentas = getByTipoCuentas

export function parametrosDdjjResource() {
  function findAllParametrosDdjj() {
    return new Promise((resolve, reject) => {
      axios
        .get(pathGetAllParametrosDdjj)
        .then(results => {
          resolve(results.data)
        })
        .catch(e => {
          reject(e.response)
        })
    })
  }
  function findByConveniosLaborales() {
    return new Promise((resolve, reject) => {
      axios
        .get(pathGetConveniosTrabajo)
        .then(results => {
          resolve(results.data)
        })
        .catch(e => {
          reject(e.response)
        })
    })
  }
  function findParametrosFilterConvenios(convenioId) {
    return new Promise((resolve, reject) => {
      axios
        .get(pathGetByFilterConvenioTrabajo + convenioId)
        .then(results => {
          resolve(results.data)
        })
        .catch(e => {
          reject(e.response)
        })
    })
  }
  function updateParametroDdjj(id, parametroDdjjDto) {
    return new Promise((resolve, reject) => {
      axios
        .put(pathPutParametroDdjj + id, parametroDdjjDto)
        .then(results => {
          resolve(results.data)
        })
        .catch(e => {
          reject(e.response)
        })
    })
  }
  function findAllByTipoPresentacion() {
    return new Promise((resolve, reject) => {
      axios
        .get(pathGetTipoPresentacion)
        .then(results => {
          resolve(results.data)
        })
        .catch(e => {
          reject(e.response)
        })
    })
  }
  function findAllByMontoBaseParaProceso() {
    return new Promise((resolve, reject) => {
      axios
        .get(pathGetByMontoBaseParaProceso)
        .then(results => {
          resolve(results.data)
        })
        .catch(e => {
          reject(e.response)
        })
    })
  }
  function findAllByTipoCuentas() {
    return new Promise((resolve, reject) => {
      axios
        .get(pathGetByTipoCuentas)
        .then(results => {
          resolve(results.data)
        })
        .catch(e => {
          reject(e.response)
        })
    })
  }
  function findAllByPlazoAlmacenamientoArchivos() {
    return new Promise((resolve, reject) => {
      axios
        .get(pathGetByPlazoAlmacenamientoArchivos)
        .then(results => {
          resolve(results.data)
        })
        .catch(e => {
          reject(e.response)
        })
    })
  }
  function findParametrosActualizados() {
    return new Promise((resolve, reject) => {
      axios
        .get(pathGetItemsActualizados)
        .then(results => {
          resolve(results.data)
        })
        .catch(e => {
          reject(e.response)
        })
    })
  }
  return {
    findAllParametrosDdjj,
    findParametrosFilterConvenios,
    findByConveniosLaborales,
    updateParametroDdjj,
    findAllByTipoPresentacion,
    findAllByMontoBaseParaProceso,
    findParametrosActualizados,
    findAllByPlazoAlmacenamientoArchivos,
    findAllByTipoCuentas,
  }
}
