import axios from 'axios'
import {
  getCuentaBancaria,
  getByIsActivoCuentaBancaria,
  postCuentaBancaria,
  putCuentaBancaria,
  getFilterByNumeroCuenta,
} from '@/utils/paths'

const pathGetCuentaBancaria = getCuentaBancaria
const pathGetByIsActivoCuentaBancaria = getByIsActivoCuentaBancaria
const pathPostCuentaBancaria = postCuentaBancaria
const pathPutCuentaBancaria = putCuentaBancaria
const pathGetFilterByNumeroCuenta = getFilterByNumeroCuenta

export function cuentaBancariaResource() {
  function findAllCuentasBancarias() {
    return new Promise((resolve, reject) => {
      axios
        .get(pathGetCuentaBancaria)
        .then(results => {
          resolve(results.data)
        })
        .catch(e => {
          reject(e.response)
        })
    })
  }
  function findAllByIsActivoCuentasBancarias() {
    return new Promise((resolve, reject) => {
      axios
        .get(pathGetByIsActivoCuentaBancaria)
        .then(results => {
          resolve(results.data)
        })
        .catch(e => {
          reject(e.response)
        })
    })
  }
  function findByNumeroCuenta(nroCuenta) {
    return new Promise((resolve, reject) => {
      axios
        .get(pathGetFilterByNumeroCuenta + nroCuenta)
        .then(results => {
          resolve(results.data)
        })
        .catch(e => {
          reject(e.response)
        })
    })
  }
  function saveCuentaBancaria(CuentaBancariaDto) {
    return new Promise((resolve, reject) => {
      axios
        .post(pathPostCuentaBancaria, CuentaBancariaDto)
        .then(results => {
          resolve(results.data)
        })
        .catch(e => {
          reject(e.response)
        })
    })
  }
  function updateCuentaBancaria(id, CuentaBancariaDto) {
    return new Promise((resolve, reject) => {
      axios
        .put(pathPutCuentaBancaria + id, CuentaBancariaDto)
        .then(results => {
          resolve(results.data)
        })
        .catch(e => {
          reject(e.response)
        })
    })
  }
  return {
    findAllCuentasBancarias,
    findAllByIsActivoCuentasBancarias,
    saveCuentaBancaria,
    updateCuentaBancaria,
    findByNumeroCuenta,
  }
}
