<template>
  <div>
    <validation-observer ref="abmCuentaBancariaForm">
      <b-form>
        <b-overlay
          :show="isWorking"
          :rounded="overlayConfig.rounded"
          :variant="overlayConfig.variant"
          :opacity="overlayConfig.opacity"
          :blur="overlayConfig.blur"
        >
          <template #overlay>
            <div class="mt-2">
              <div style="text-align: center">
                <span class="spinner-border " />
              </div>
              <div style="text-align: center">
                <small>{{ overlayConfig.message }}</small>
              </div>
            </div>
          </template>
          <b-card>
            <b-row>
              <b-col md="5">
                <b-form-group
                  id="frmEntidadBancaria"
                  label="Entidad bancaria"
                >
                  <validation-provider
                    name="entidadBancaria"
                    rules="required"
                  >
                    <div slot-scope="{ errors, failedRules }">

                      <small v-if="isBusyBuscandoEntidadBancaria">
                        <span
                          v-if="isBusyBuscandoEntidadBancaria"
                          class="spinner-border spinner-border-sm"
                          aria-hidden="true"
                        /> Buscando entidad bancaria
                      </small>
                      <b-form-select
                        v-if="!isBusyBuscandoEntidadBancaria"
                        id="selectEntidadBancaria"
                        v-model="itemForm.entidadBancaria.id"
                        v-b-tooltip.hover="'Seleccione la entidad bancaria'"
                        :state="errors.length > 0 ? false : null"
                        :options="optionsEntidadBancaria"
                        value-field="id"
                        text-field="entidadBancaria"
                        :aria-busy="isBusyBuscandoEntidadBancaria ? 'true' : 'false'"
                        :disabled="isBusyBuscandoEntidadBancaria"
                        rounded
                      >
                        <template #first>
                          <b-form-select-option
                            v-if="!isBusyBuscandoEntidadBancaria"
                            :value="null"
                            disabled
                          >
                            Seleccione
                          </b-form-select-option>
                        </template>
                      </b-form-select>
                      <small
                        v-if="failedRules.required && !isBusyBuscandoEntidadBancaria"
                        class="text-danger"
                      >
                        Entidad bancaria obligatoria.
                      </small>
                    </div>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col md="1">
                <b-button
                  v-if="permisosIns.includes(nameModulo)"
                  id="btnAddEntidadBancaria"
                  v-b-tooltip.hover
                  squared
                  style="margin-top: 2rem; margin-left: -28px; height: 36px;"
                  size="sm"
                  title="Nueva entidad bancaria"
                  variant="success"
                  @click="() => addEntidadBancaria()"
                >
                  <feather-icon icon="PlusIcon" />
                </b-button>
              </b-col>
              <b-col md="6">
                <b-form-group
                  id="frmTipoCuenta"
                  label="Tipo de cuenta"
                >
                  <validation-provider
                    name="tipoCuenta"
                    rules="required"
                  >
                    <div slot-scope="{ errors, failedRules }">

                      <small v-if="isBusyBuscandoTipoCuentas">
                        <span
                          v-if="isBusyBuscandoTipoCuentas"
                          class="spinner-border spinner-border-sm"
                          aria-hidden="true"
                        /> Buscando tipos de cuentas
                      </small>
                      <b-form-select
                        v-if="!isBusyBuscandoTipoCuentas"
                        id="selectTipoCuentas"
                        v-model="itemForm.dominio.id"
                        v-b-tooltip.hover="'Seleccione el tipo de cuenta'"
                        :state="errors.length > 0 ? false : null"
                        :options="optionsTipoCuentas"
                        value-field="id"
                        text-field="nombre"
                        :aria-busy="isBusyBuscandoTipoCuentas ? 'true' : 'false'"
                        :disabled="isBusyBuscandoTipoCuentas"
                        rounded
                      >
                        <template #first>
                          <b-form-select-option
                            v-if="!isBusyBuscandoTipoCuentas"
                            :value="null"
                            disabled
                          >
                            Seleccione
                          </b-form-select-option>
                        </template>
                      </b-form-select>
                      <small
                        v-if="failedRules.required && !isBusyBuscandoTipoCuentas"
                        class="text-danger"
                      >
                        Cuenta obligatoria.
                      </small>
                    </div>
                  </validation-provider>
                </b-form-group>
              </b-col>
            </b-row>

            <b-row>
              <b-col md="6">
                <b-form-group
                  id="frmNumeroCuenta"
                  label="Número de cuenta"
                >
                  <validation-provider
                    name="numeroCuenta"
                    rules="required|max:30"
                  >
                    <div slot-scope="{ errors, failedRules }">
                      <b-form-input
                        id="txtnumeroCuenta"
                        v-model="itemForm.numeroCuenta"
                        :state="errors.length > 0 ? false : null"
                        autocomplete="off"
                      />
                      <small
                        v-if="failedRules.required"
                        class="text-danger"
                      >Número de cuenta obligatorio.</small>
                      <small
                        v-if="failedRules.max"
                        class="text-danger"
                      >Máximo 30 caracteres.</small>
                    </div>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col md="6">
                <b-form-group
                  id="frmNumeroCbu"
                  label="Número de CBU"
                >
                  <validation-provider
                    name="numeroCbu"
                    rules="required|max:22|min:22"
                  >
                    <div slot-scope="{ errors, failedRules }">
                      <b-form-input
                        id="txtnumeroCbu"
                        v-model="itemForm.numeroCbu"
                        maxlength="22"
                        :state="errors.length > 0 ? false : null"
                        autocomplete="off"
                        @keypress="validarCbu"
                      />
                      <small
                        v-if="failedRules.required"
                        class="text-danger"
                      >Número de CBU obligatorio.</small>
                      <small
                        v-if="failedRules.max"
                        class="text-danger"
                      >Máximo 22 caracteres.</small>
                      <small
                        v-if="failedRules.min"
                        class="text-danger"
                      >Son requeridos 22 caracteres.</small>
                    </div>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col md="2">
                <b-form-group
                  id="frmChecknCuentaBancaria"
                  label="Estado"
                >
                  <b-form-checkbox
                    v-model="itemForm.habilitado"
                    v-b-tooltip.hover="'Activar / Desactivar'"
                    class="custom-control-success"
                    name="check-button"
                    switch
                  >
                    <small class="switch-icon-left">
                      <feather-icon icon="CheckIcon" />
                    </small>
                  </b-form-checkbox>
                </b-form-group>
              </b-col>
            </b-row>
          </b-card>
        </b-overlay>
        <hr>
        <b-row>
          <div>
            <b-col md="12">
              <b-button
                id="btnGCancelarCuentaBancaria"
                v-b-tooltip.hover
                title="Volver"
                variant="light"
                squared
                :disabled="isWorking"
                @click="() => cancelar()"
              >
                <feather-icon icon="ArrowLeftIcon" />
              </b-button>
            </b-col>
          </div>
          <div class="align-self-end ml-auto">
            <b-col md="12">
              <b-button
                v-if="permisosIns.includes(nameModulo)"
                id="btnGuardarCuentaBancaria"
                v-b-tooltip.hover
                variant="primary"
                squared
                title="Guardar"
                :disabled="isWorking"
                type="submit"
                @click.prevent="validationItemsForm"
              >
                <font-awesome-icon
                  v-if="!isWorking"
                  icon="fa-solid fa-floppy-disk"
                />
                <span
                  v-if="isWorking"
                  class="spinner-border spinner-border-sm"
                  aria-hidden="true"
                />
              </b-button>
            </b-col>
          </div>
        </b-row>
      </b-form>
    </validation-observer>
    <div v-if="showEntidadBancariaModal">
      <cuentas-bancarias-modal
        :show-entidad-bancaria-modal="showEntidadBancariaModal"
        :open-mode-modal="openModeModal"
        :item="item"
        @closeModalEntidadBancaria="closeModalEntidadBancaria"
        @agregarEntidadBancaria="agregarEntidadBancaria"
      />
    </div>
  </div>
</template>
<script>
import { mapGetters } from 'vuex'
import { cuentaBancariaResource } from '@/services/cuentaBancariaResource'
import { parametrosDdjjResource } from '@/services/parametrosDdjjResource'
import { entidadBancariaResource } from '@/services/entidadBancariaResource'
import CuentasBancariasModal from './CuentasBancariasModal.vue'

export default {
  components: {
    CuentasBancariasModal,
  },
  props: {
    item: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      showEntidadBancariaModal: false,
      isBusyBuscandoTipoCuentas: false,
      isBusyBuscandoEntidadBancaria: false,
      optionsEntidadBancaria: [],
      optionsTipoCuentas: [],
      nameModulo: 'ABM_PARAMETROS_DDJJ',
      isWorking: false,
      itemForm: {
        entidadBancaria: {
          id: null,
          nombre: null,
        },
        dominio: {
          id: null,
          nombre: null,
        },
        numeroCuenta: null,
        habilitado: null,
      },
      overlayConfig: {
        rounded: 'sm',
        variant: 'transparent',
        opacity: '0.8',
        blur: '5px',
        message: '',
      },
    }
  },
  computed: {
    ...mapGetters(
      {
        permisosVer: 'getPermisosVer',
        permisosDel: 'getPermisosDel',
        permisosIns: 'getPermisosIns',
        permisosUpd: 'getPermisosUpd',
      },
    ),
  },
  async created() {
    if (this.item === null) {
      this.itemForm.habilitado = true
    } else {
      this.itemForm = { ...this.item }
    }
    await this.getTipoCuentasLista()
    await this.getEntidadBancariaLista()
  },
  methods: {
    validarCbu(evt) {
      const charCode = (evt.which) ? evt.which : evt.keyCode
      if (charCode >= 48 && charCode <= 57) {
        return true
      }
      evt.preventDefault()
      return false
    },
    cancelar() {
      this.$emit('cancelar')
    },
    validationItemsForm() {
      this.$refs.abmCuentaBancariaForm.validate().then(success => {
        if (success) {
          this.actionItem()
        }
      })
    },
    actionItem() {
      if (this.itemForm.id != null) {
        this.update()
      } else {
        this.save()
      }
    },
    save() {
      this.overlayConfig.message = 'Guardando ...'
      this.isWorking = true
      cuentaBancariaResource().saveCuentaBancaria(this.itemForm).then(res => {
        this.$emit('guardar', res)
      }).catch(error => {
        console.error(error)
      })
        .finally(() => {
          this.isWorking = false
          this.overlayConfig.message = ''
        })
    },
    update() {
      this.overlayConfig.message = 'Guardando ...'
      this.isWorking = true
      cuentaBancariaResource().updateCuentaBancaria(this.itemForm.id, this.itemForm).then(res => {
        this.$emit('guardar', res)
      }).catch(error => {
        console.error(error)
      })
        .finally(() => {
          this.isWorking = false
          this.overlayConfig.message = ''
        })
    },
    addEntidadBancaria() {
      this.openModeModal = 'new'
      this.showEntidadBancariaModal = true
      this.item = null
    },
    closeModalEntidadBancaria() {
      this.showEntidadBancariaModal = false
    },
    agregarEntidadBancaria(entidadBancaria) {
      this.showEntidadBancariaModal = false
      this.optionsEntidadBancaria.push(entidadBancaria)
    },
    getTipoCuentasLista() {
      this.isBusyBuscandoTipoCuentas = true
      parametrosDdjjResource().findAllByTipoCuentas().then(result => {
        this.optionsTipoCuentas = result
      }).catch(error => {
        console.error('error:', error)
      })
        .finally(() => {
          this.isBusyBuscandoTipoCuentas = false
        })
    },
    getEntidadBancariaLista() {
      this.isBusyBuscandoEntidadBancaria = true
      entidadBancariaResource().findAllByIsActivoEntidadesBancarias().then(result => {
        this.optionsEntidadBancaria = result
      }).catch(error => {
        console.error('error:', error)
      })
        .finally(() => {
          this.isBusyBuscandoEntidadBancaria = false
        })
    },
  },
}
</script>
