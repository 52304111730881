var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('validation-observer',{ref:"abmParametrosDdjjForm"},[_c('b-form',[_c('b-overlay',{attrs:{"show":_vm.isWorking,"rounded":_vm.overlayConfig.rounded,"variant":_vm.overlayConfig.variant,"opacity":_vm.overlayConfig.opacity,"blur":_vm.overlayConfig.blur},scopedSlots:_vm._u([{key:"overlay",fn:function(){return [_c('div',{staticClass:"mt-2"},[_c('div',{staticStyle:{"text-align":"center"}},[_c('span',{staticClass:"spinner-border "})]),_c('div',{staticStyle:{"text-align":"center"}},[_c('small',[_vm._v(_vm._s(_vm.overlayConfig.message))])])])]},proxy:true}])},_vm._l((_vm.parametrosList),function(param,index){return _c('b-card',{key:index},[(param.parametro !== 'TIPO DE PRESENTACIÓN')?_c('b-row',[_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"id":"frmParametro","label":"Parámetro"}},[_vm._v(" "+_vm._s(param.parametro)+" ")])],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',[_c('font-awesome-icon',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover.righttop",value:(param.descripcion),expression:"param.descripcion",modifiers:{"hover":true,"righttop":true}}],staticClass:"mr-2",attrs:{"icon":"fa-solid fa-question-circle"}})],1)],1)],1):_vm._e(),(param.parametro === 'ESTADO LABORAL TRABAJADORES ACTIVOS' || param.parametro === 'ESTADO LABORAL TRABAJADORES INACTIVOS' || param.estadoLaboral !== null)?_c('b-row',[_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"id":"frmValor","label":"Valor"}},[_c('validation-provider',{attrs:{"name":"valor","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var failedRules = ref.failedRules;
return _c('div',{},[(_vm.isBusyBuscandoEstadoLaboral)?_c('small',[(_vm.isBusyBuscandoEstadoLaboral)?_c('span',{staticClass:"spinner-border spinner-border-sm",attrs:{"aria-hidden":"true"}}):_vm._e(),_vm._v(" Buscando valor ")]):_vm._e(),(!_vm.isBusyBuscandoEstadoLaboral)?_c('b-form-select',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover",value:('Seleccione el valor'),expression:"'Seleccione el valor'",modifiers:{"hover":true}}],attrs:{"id":"selectEstadoLaboral","state":errors.length > 0 ? false : null,"options":_vm.optionsEstadoLaboral,"value-field":"id","text-field":"nombre","aria-busy":_vm.isBusyBuscandoEstadoLaboral ? 'true' : 'false',"disabled":_vm.isBusyBuscandoEstadoLaboral,"rounded":""},scopedSlots:_vm._u([{key:"first",fn:function(){return [(!_vm.isBusyBuscandoEstadoLaboral)?_c('b-form-select-option',{attrs:{"value":null,"disabled":""}},[_vm._v(" Seleccione ")]):_vm._e()]},proxy:true}],null,true),model:{value:(param.estadoLaboral.id),callback:function ($$v) {_vm.$set(param.estadoLaboral, "id", $$v)},expression:"param.estadoLaboral.id"}}):_vm._e(),(failedRules.required && !_vm.isBusyBuscandoEstadoLaboral)?_c('small',{staticClass:"text-danger"},[_vm._v(" valor obligatorio. ")]):_vm._e()],1)}}],null,true)})],1)],1)],1):_vm._e(),(param.parametro === 'MONTO BASE PARA PROCESO DE DDJJ')?_c('b-row',[_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"id":"frmValor","label":"Valor"}},[_c('validation-provider',{attrs:{"name":"valor","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var failedRules = ref.failedRules;
return _c('div',{},[(_vm.isBusyBuscandoMontoBaseParaProceso)?_c('small',[(_vm.isBusyBuscandoMontoBaseParaProceso)?_c('span',{staticClass:"spinner-border spinner-border-sm",attrs:{"aria-hidden":"true"}}):_vm._e(),_vm._v(" Buscando valor ")]):_vm._e(),(!_vm.isBusyBuscandoMontoBaseParaProceso)?_c('b-form-select',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover",value:('Seleccione el valor'),expression:"'Seleccione el valor'",modifiers:{"hover":true}}],attrs:{"id":"selectMontoBaseParaProceso","state":errors.length > 0 ? false : null,"options":_vm.optionsMontoBaseParaProceso,"value-field":"id","text-field":"nombre","aria-busy":_vm.isBusyBuscandoMontoBaseParaProceso ? 'true' : 'false',"disabled":_vm.isBusyBuscandoMontoBaseParaProceso,"rounded":""},scopedSlots:_vm._u([{key:"first",fn:function(){return [(!_vm.isBusyBuscandoMontoBaseParaProceso)?_c('b-form-select-option',{attrs:{"value":null,"disabled":""}},[_vm._v(" Seleccione ")]):_vm._e()]},proxy:true}],null,true),model:{value:(param.dominio.id),callback:function ($$v) {_vm.$set(param.dominio, "id", $$v)},expression:"param.dominio.id"}}):_vm._e(),(failedRules.required && !_vm.isBusyBuscandoMontoBaseParaProceso)?_c('small',{staticClass:"text-danger"},[_vm._v(" valor obligatorio. ")]):_vm._e()],1)}}],null,true)})],1)],1)],1):_vm._e(),(param.parametro === 'PLAZO DE ALMACENAMIENTO DE ARCHIVOS')?_c('b-row',[_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":"Plazo de Almacenamiento (años)"}},[_c('validation-provider',{attrs:{"name":"valor","rules":_vm.getPlazoAlmacenamientoRules()},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var failedRules = ref.failedRules;
return _c('div',{},[_c('b-form-input',{attrs:{"id":"txtValor","state":errors.length > 0 ? false : null,"type":"number","min":"minimo","max":"maximo"},model:{value:(param.valor),callback:function ($$v) {_vm.$set(param, "valor", $$v)},expression:"param.valor"}}),(failedRules.required)?_c('small',{staticClass:"text-danger"},[_vm._v("Valor obligatorio.")]):_vm._e(),(failedRules.max_value)?_c('small',{staticClass:"text-danger"},[_vm._v("Valor excedido (máximo "+_vm._s(_vm.maximo)+").")]):_vm._e(),(failedRules.min_value)?_c('small',{staticClass:"text-danger"},[_vm._v("Valor por debajo del mínimo (mínimo "+_vm._s(_vm.minimo)+").")]):_vm._e()],1)}}],null,true)})],1)],1)],1):_vm._e(),(param.parametro === 'RELACIÓN MONTO NO REMUNERATIVO')?_c('b-row',[_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":"Relación Monto No Remunerativo (%)"}},[_c('validation-provider',{attrs:{"name":"valor","rules":"required|max_value:100|min_value:0"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var failedRules = ref.failedRules;
return _c('div',{},[_c('b-form-input',{attrs:{"id":"txtValor","state":errors.length > 0 ? false : null,"type":"number","min":"0","max":"100"},model:{value:(param.valor),callback:function ($$v) {_vm.$set(param, "valor", $$v)},expression:"param.valor"}}),(failedRules.required)?_c('small',{staticClass:"text-danger"},[_vm._v("Valor obligatorio.")]):_vm._e(),(failedRules.max_value)?_c('small',{staticClass:"text-danger"},[_vm._v("Valor excedido (máximo 100).")]):_vm._e(),(failedRules.min_value)?_c('small',{staticClass:"text-danger"},[_vm._v("Valor por abajo del mínimo (mínimo 0).")]):_vm._e()],1)}}],null,true)})],1)],1)],1):_vm._e(),_c('hr',{staticStyle:{"margin-bottom":"0"}})],1)}),1),_c('b-row',[_c('div',[_c('b-col',{attrs:{"md":"12"}},[_c('b-button',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover",modifiers:{"hover":true}}],attrs:{"id":"btnGCancelarParametrosDdjj","title":"Volver","variant":"light","squared":"","disabled":_vm.isWorking},on:{"click":function () { return _vm.cancelar(); }}},[_c('feather-icon',{attrs:{"icon":"ArrowLeftIcon"}})],1)],1)],1),_c('div',{staticClass:"align-self-end ml-auto"},[_c('b-col',{attrs:{"md":"12"}},[(_vm.permisosIns.includes(_vm.nameModulo))?_c('b-button',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover",modifiers:{"hover":true}}],attrs:{"id":"btnGuardarParametrosDdjj","variant":"primary","squared":"","title":"Guardar","disabled":_vm.isWorking,"type":"submit"},on:{"click":function($event){$event.preventDefault();return _vm.validationItemsForm($event)}}},[(!_vm.isWorking)?_c('font-awesome-icon',{attrs:{"icon":"fa-solid fa-floppy-disk"}}):_vm._e(),(_vm.isWorking)?_c('span',{staticClass:"spinner-border spinner-border-sm",attrs:{"aria-hidden":"true"}}):_vm._e()],1):_vm._e()],1)],1)])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }